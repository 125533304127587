<template>
    <div class="admines">
        <!-- 顶部收入数据部分 -->
        <div class="income">
            <div class="income_son">
                <div class="tuition">
                    <div class="mo_num">{{month_num}}元</div>
                    <div class="mo_title">本月学费收入</div>
                </div>
                <div class="icon_img">
                    <img src="../../assets/imgs/ab.png" alt="">
                </div>
            </div>
            <div class="income_son">
                <div class="tuition">
                    <div class="mo_num">{{all_num}}元</div>
                    <div class="mo_title">累计学费收入</div>
                </div>
                <div class="icon_img">
                    <img src="../../assets/imgs/ab.png" alt="">
                </div>
            </div>
            <div class="income_son">
                <div class="tuition">
                    <div class="mo_num">{{act_num}}元</div>
                    <div class="mo_title">本月活动收入</div>
                </div>
                <div class="icon_img">
                    <img src="../../assets/imgs/ab.png" alt="">
                </div>
            </div>
            <div class="income_son">
                <div class="tuition">
                    <div class="mo_num">{{all_active}}元</div>
                    <div class="mo_title">累计活动收入</div>
                </div>
                <div class="icon_img">
                    <img src="../../assets/imgs/ab.png" alt="">
                </div>
            </div>
        </div>
        <!-- 统计图部分 -->
        <div class="Statistical">
            <div class="left_chart">
                <div class="top_sel">
                    <div class="top_name">到校状态</div>
                    <el-select v-model="school_id" placeholder="校区" clearable size="mini" @change="indexLeft">
                        <el-option v-for="item in school_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </div>
                <div class="annularDiv" ref="annular"></div>
            </div>
            <div class="right_chart">
                <div class="top_sel">
                    <div class="top_name">月度统计</div>
                    <el-select v-model="campus_id" placeholder="校区" clearable size="mini" @change="indexRight">
                        <el-option v-for="item in campus_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </div>
                <div class="brokenDiv" ref="broken"></div>
            </div>
        </div>
        <!-- 班级数据部分 -->
        <div class="name_class">
            <div class="top_sel">
                <div class="top_name">班级情况</div>
                <el-select v-model="class_id" placeholder="校区" clearable size="mini" @change="indexbottom">
                    <el-option v-for="item in class_arr" :key="item.uid" :label="item.name" :value="item.uid">
                    </el-option>
                </el-select>
            </div>
            <!-- 表格数据 -->
            <div>
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%"
                    :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                    <el-table-column prop="uid" v-if="show=false">
                    </el-table-column>
                    <el-table-column prop="name" label="班级">
                    </el-table-column>
                    <el-table-column prop="count" label="人数/人">
                    </el-table-column>
                    <el-table-column prop="is_in" label="已入园/人">
                    </el-table-column>
                    <el-table-column prop="not_in" label="未入园/人">
                    </el-table-column>
                    <el-table-column prop="leave" label="请假/人">
                    </el-table-column>
                    <el-table-column prop="complete" label="离校/人">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script> 
import { topIndexApi, leftIndexApi, rightIndexApi, bottomIndexApi } from '@/api'
export default {
    data () {
        return {
            // 登录用户id
            user_id: '',
            month_num: '',   //本月学费收入
            all_num: '',     //累计学费收入
            act_num: '',    //本月活动收入
            all_active: '',    //累计活动收入
            school_id: '',   //到校状态下拉
            school_arr: [],
            school_num: '',   //总人数
            admiss: '',    //入校
            duty: '',     //缺勤
            leave: '',    //请假
            campus_id: '',   //月度统计下拉
            campus_arr: [],
            x_arr: [],   //统计图x轴数据
            y_max: '',   //y轴最大值
            y_step: '',   //y轴步进
            one_count: [],   //第一条数据
            two_count: [],    //第二条数据
            class_id: '',    //班级下拉
            class_arr: [],
            tableData: [],   //班级数据
        }
    },
    created () {
        this.indexTop();
        this.indexLeft();
        this.indexRight();
        this.indexbottom();
    },
    methods: {
        // 首页顶部
        indexTop () {
            this.user_id = sessionStorage.getItem('id');
            topIndexApi({
                auth_userid: this.user_id,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.month_num = res.month_financial;  //本月学费收入
                    this.all_num = res.all_financial;    //累计学费收入
                    this.act_num = res.month_active;     //本月活动收入
                    this.all_active = res.all_active;    //累计活动收入
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 首页中间左侧
        indexLeft () {
            this.user_id = sessionStorage.getItem('id');
            leftIndexApi({
                auth_userid: this.user_id,
                campus_uid: this.school_id,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.school_arr = res.campus;   //校区
                    this.school_num = res.count;   //总人数
                    this.admiss = res.is_in;     //已入园
                    this.duty = res.not_in;      //缺勤
                    this.leave = res.leave;      //请假
                    this.init_annular();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 首页中间右侧
        indexRight () {
            this.user_id = sessionStorage.getItem('id');
            rightIndexApi({
                auth_userid: this.user_id,
                campus_uid: this.campus_id,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.campus_arr = res.campus;   //校区
                    this.x_arr = res.view.date;   //x周数据
                    this.y_max = res.view.max;    //y轴最大值
                    this.y_step = res.view.step;    //y轴步进
                    // console.log(this.y_step)
                    this.one_count = res.view.active;   //第一条数据  活动
                    this.two_count = res.view.financial;   //第二条数据  学费
                    this.init_broken()
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 首页底部
        indexbottom () {
            this.user_id = sessionStorage.getItem('id');
            bottomIndexApi({
                auth_userid: this.user_id,
                campus_uid: this.class_id,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.class_arr = res.campus;   //校区
                    this.tableData = res.data;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 环形统计图数据
        init_annular () {
            let annular_chart = this.$echarts.init(this.$refs.annular);
            // 绘制图表
            annular_chart.setOption({
                title: {
                    text: '总人数',
                    subtext: this.school_num,
                    subtextStyle: {
                        color: '#333333',

                    },
                    left: 'center',
                    top: '40%'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: '30%',
                    left: '80%',   //图例组件离容器左侧的距离
                },
                series: [
                    {
                        type: 'pie',   //形状为饼图
                        radius: ["50%", "70%"], // 这个属性修改圆环宽度
                        avoidLabelOverlap: false, //是否启用防止标签重叠策略
                        label: {   //饼图图形上的文本标签
                            show: false,
                            position: 'center'
                        },
                        emphasis: {  //文本标签在中间是否显示 以及显示样式
                            label: {
                                show: false,
                                // fontSize: '16',
                                // fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.admiss, name: '入校', itemStyle: { color: '#5ec9db' } },
                            { value: this.duty, name: '缺勤', itemStyle: { color: '#f27d51' } },
                            { value: this.leave, name: '请假', itemStyle: { color: '#fdc765' } },
                        ]
                    }
                ]
            })
        },
        // 折线图
        init_broken () {
            let broken_chart = this.$echarts.init(this.$refs.broken);
            broken_chart.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                color: ['#519cf4', '#ff635a'],
                legend: {
                    left: '10%',
                    top: '5%',
                    // orient: 'vertical',
                    data: ['活动', '学费']
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '5%',
                    containLabel: true,
                    width: '95%'
                },
                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: ['5%', '5%'],
                    data: this.x_arr,
                    axisTick: { //关键设置，坐标轴刻度也不显示
                        show: false,
                    },
                    splitLine: {  //去掉背景网格线
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: this.y_max,
                    interval: this.y_step,
                    axisTick: { //关键设置，坐标轴刻度也不显示
                        show: false,
                    },
                    splitLine: {  //去掉背景网格线
                        show: false,
                    },
                },
                series: [
                    {
                        name: '活动',
                        type: 'line',
                        smooth: true,
                        data: this.one_count
                    },
                    {
                        name: '学费',
                        type: 'line',
                        smooth: true,
                        data: this.two_count
                    }
                ]
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.admines {
    width: 100%;
    height: 100%;
    // 顶部收入数据部分
    .income {
        width: 100%;
        // height: 250px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // 顶部小卡片
        .income_son {
            width: 192px;
            height: 64px;
            border-radius: 12px;
            background: #ffffff;
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .tuition {
                .mo_num {
                    font-size: 24px;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 8px;
                }
                .mo_title {
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                }
            }
            .icon_img {
                width: 24px;
                height: 24px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    // 统计图部分
    .Statistical {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .left_chart {
            width: 35%;
            height: 334px;
            border-radius: 12px;
            background: #ffffff;
            margin-right: 30px;
            padding: 32px;
            .top_sel {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .top_name {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                }
                .el-input {
                    width: 100px;
                }
                ::v-deep.el-input__inner {
                    width: 140px;
                }
            }
            .annularDiv {
                width: 100%;
                height: 290px;
                position: relative;
                left: -58px;
                top: 0;
            }
        }
        .right_chart {
            width: 65%;
            height: 334px;
            border-radius: 12px;
            background: #ffffff;
            padding: 32px;
            .top_sel {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .top_name {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                }
                .el-input {
                    width: 100px;
                }
                ::v-deep.el-input__inner {
                    width: 140px;
                }
            }
            .brokenDiv {
                width: 90%;
                height: 300px;
                position: relative;
                left: 50px;
                top: 0;
                margin-top: 10px;
            }
        }
    }
    //表格部分
    .name_class {
        width: 96%;
        // height: 234px;
        border-radius: 12px;
        background: #ffffff;
        padding: 30px;
        .top_sel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .top_name {
                font-size: 18px;
                font-weight: 600;
                color: #000000;
            }
            .el-input {
                width: 100px;
            }
            ::v-deep.el-input__inner {
                width: 140px;
            }
        }
    }
}
</style>